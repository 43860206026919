import React from "react";
import {
  Box,
  Grid,
  MenuList,
  MenuItem,
  Stack,
  Pagination,
  Button,
} from "@mui/material";
import UserCard from "./UserCard";
import constants from "constants/english/settings";
import { useAxios } from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";
import SearchBar from "./SearchBar";
import Helpers from "utils/commonHelpers";
import CustomDialog from "components/common/CustomDialog";
import { AddUserForm } from "./AddUserForm";

const pageSize = 20;

export const UsersTab = () => {
  const { user } = useAuth();
  const [data, isLoading, error, refresh] = useAxios(`user?companyId=${user.companyId}`);
  const usersData = data?.response || [];
  const [searchValue, setSearchValue] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const usersToBeDisplay = searchValue
    ? usersData.filter((user) =>
      Boolean(Helpers.searchValueInNestedObject(user, searchValue)),
    )
    : usersData;

  const handleDeleteUser = async ({ uid }) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete the selected user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        MySwal.showLoader("Processing...");
        const response = await customAxios.put(`deleteUser/${uid}`);
        if (response?.status === 200 || response?.status === 201) {
          refresh();
          MySwal.fire({
            title: "User Deleted",
            text: "The selected user has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const handleEnableDisableUser = async ({ uid, suspended }) => {
    MySwal.showLoader("Processing...");
    const response = await customAxios.patch(`user/${uid}`, { suspended });
    if (response?.status === 200 || response?.status === 201) {
      refresh();
      MySwal.fire({
        title: `User ${suspended ? "Disabled" : "Enabled"}!`,
        text: "",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <CustomDialog
            AnchorComponent={(anchorProps) => (
              <Button {...anchorProps} variant="contained" disableElevation>
                Add New User
              </Button>
            )}
            title="Add User"
            Content={(dialogProps) => (
              <AddUserForm {...dialogProps} callback={refresh} />
            )}
          />
        </Grid>
        <Grid item>
          <SearchBar setSearchValue={setSearchValue} />
        </Grid>
      </Grid>
      <Box mt={2}>
        {isLoading ? (
          "Loading..."
        ) : error ? (
          "Unable to load data!"
        ) : (
          <Stack alignItems="center" spacing={3}>
            <Box sx={{ width: '100%'}}>
              <Grid container spacing={2} rowSpacing={2}>
                {usersToBeDisplay
                  .slice(pageSize * (pageNumber - 1), pageSize * pageNumber)
                  .map((usr) => (
                    <Grid item lg={4} md={6} xs={12} key={usr._id}>
                      <UserCard
                        name={usr.name}
                        email={usr.email}
                        role={constants.ROLE_MAP[usr.role] || "User"}
                        status={!usr.suspended}
                        statusText={usr.suspended ? "Disabled" : "Enabled"}
                        more={
                          usr.email !== user.email && (
                            <MenuList autoFocus={false}>
                              <MenuItem onClick={() => handleDeleteUser({ uid: usr.uid })}>
                                Delete User
                              </MenuItem>
                              <MenuItem onClick={() => handleEnableDisableUser({ uid: usr.uid, suspended: !usr.suspended })}>
                                {usr.suspended ? "Enable User" : "Disable User"}
                              </MenuItem>
                            </MenuList>
                          )
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {Math.ceil(usersToBeDisplay.length / pageSize) > 1 && (
              <Pagination
                defaultPage={pageNumber}
                count={Math.ceil(usersToBeDisplay.length / pageSize)}
                color="primary"
                onChange={(e, pageNumber) => {
                  setPageNumber(pageNumber);
                }}
              />
            )}
          </Stack>
        )}
      </Box>
    </>
  );
};