import React from "react";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MergeEmailPopup from "./MergeEmailPopup";
import { customAxios, useAxios } from "hooks/useAxios";
import prettyBytes from "pretty-bytes";
import { Box } from "@mui/system";
import GetStoragePopup from "./GetStoragePopup";
import CancelSubscribePopup from "./CancelSubscribePopup";
import CustomDialog from "components/common/CustomDialog";

export default function Profile() {
  const { user, logout, reloadUser } = useCredulockerAuth();
  const BASIC_PLAN_NAME = "BASIC";
  const storageLimit = user?.plan?.notes?.memoryAllowed || 10000000;
  const storageUsed = ((user?.uploads?.memoryUsed || 0) / storageLimit) * 100;
  const [dialogComp, setDialogComp] = React.useState(false);
  const username = user?.name || user?.email?.[0]?.split("@")[0];

  const [skills] = useAxios(`credulocker/user/skills`, {
    attachCredulockerToken: true,
  });

  return (
    <Paper
      variant="outlined"
      component={Stack}
      sx={{
        bgcolor: "#f8f9fe",
        borderRadius: "10px",
        overflow: "hidden",
        p: 3,
        minHeight: { sm: "calc(100dvh - 32px)" },
      }}
      spacing={3}
    >
      <Stack spacing={2} alignItems="center">
        <Avatar
          sx={{
            height: "72px",
            width: "72px",
            fontSize: "2.5rem",
            borderRadius: "10px",
          }}
        >
          {username.charAt(0).toUpperCase()}
        </Avatar>
        <Stack spacing={0.75}>
          <Typography noWrap variant="h4" align="center">
            {username}
          </Typography>
          <Typography noWrap variant="body2" align="center">
            {user.email[0]}
          </Typography>
        </Stack>
        <Button startIcon={<LogoutIcon />} disableRipple onClick={logout}>
          Logout
        </Button>
      </Stack>
      <Divider />
      <Stack spacing={1} alignItems="flex-start">
        <Typography noWrap variant="subtitle2">
          Email Address{user?.email?.length > 1 ? 'es' : ''}:
        </Typography>
        {Array.isArray(user?.email) ? user?.email?.map((email) => (
          <Stack key={email} direction="row" alignItems="center" spacing={1}>
            <EmailOutlinedIcon fontSize="small" color="action" />
            <Typography variant="body2" noWrap>
              {email}
            </Typography>
          </Stack>
        )) : user?.email}
        <CustomDialog
          AnchorComponent={(props) => (
            <Button
              {...props}
              size="small"
              sx={{
                p: 0,
                px: 0.5,
              }}
              startIcon={<AddIcon />}
            >
              Add Email
            </Button>
          )}
          title="Add Email"
          Content={(props) => (
            <MergeEmailPopup
              {...props}
              warningText="If a Credulocker account already exists with this email, adding it here will merge the two accounts and all the credentials will be stored in one place."
              onVerification={({ email, otp }) => {
                return customAxios.post(
                  "otp/addemail",
                  {
                    email,
                    otp,
                  },
                  { attachCredulockerToken: true },
                ).then(response => {
                  if (response?.status === 200) {
                    reloadUser();
                  }
                });
              }}
            />
          )}
        />
      </Stack>
      <Stack spacing={1} alignItems="flex-start">
        <Typography noWrap variant="subtitle2">
          Linked Account{user?.email?.length > 1 ? 's' : ''}:
        </Typography>
        {user?.authorizedFor?.map((email) => (
          <Stack key={email} direction="row" alignItems="center" spacing={1}>
            <EmailOutlinedIcon fontSize="small" color="action" />
            <Typography variant="body2" noWrap>
              {email}
            </Typography>
          </Stack>
        ))}
        <CustomDialog
          AnchorComponent={(props) => (
            <Button
              {...props}
              size="small"
              sx={{
                p: 0,
                px: 0.5,
              }}
              startIcon={<AddIcon />}
            >
              Link An Account
            </Button>
          )}
          title="Link An Account"
          Content={(props) => (
            <MergeEmailPopup
              {...props}
              onVerification={({ email, otp }) => {
                return customAxios.post(
                  "otp/linkaccount",
                  {
                    email,
                    otp,
                  },
                  { attachCredulockerToken: true },
                ).then(response => {
                  if (response?.status === 200) {
                    reloadUser();
                  }
                });
              }}
            />
          )}
        />
      </Stack>
      {(Array.isArray(skills) && skills?.length) ? (
        <Stack spacing={1} alignItems="flex-start">
          <Typography noWrap variant="subtitle2">
            Skills Earned:
          </Typography>
          <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
            {skills.map((skill) => (
              <Chip key={skill} label={skill} />
            ))}
          </Stack>
        </Stack>
      ) : null}
      <Stack spacing={1}>
        <LinearProgress
          variant="determinate"
          color={
            storageUsed < 70
              ? "success"
              : storageUsed < 90
              ? "warning"
              : "error"
          }
          value={storageUsed > 100 ? 100 : storageUsed}
        />
        <Typography variant="body2">
          {prettyBytes(user?.uploads?.memoryUsed || 0)} of{" "}
          {prettyBytes(storageLimit)} used
        </Typography>
        <Box>
          {user?.plan?.item?.name === BASIC_PLAN_NAME ? (
            <Button variant="outlined" onClick={() => setDialogComp("storage")}>
              Get more storage
            </Button>
          ) : (
            <Stack direction="row" spacing={2}>
              <Chip
                variant="outlined"
                color="success"
                label={user?.plan?.item?.name}
              />
              <Button
                color="error"
                size="small"
                onClick={() => setDialogComp("cancel")}
              >
                Cancel Subscription
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>
      <Dialog
        size="md"
        disableEscapeKeyDown
        sx={{ zIndex: 1000 }}
        open={Boolean(dialogComp)}
        onClose={() => setDialogComp()}
      >
        {dialogComp == "email" ? (
          <MergeEmailPopup onClose={() => setDialogComp()} />
        ) : dialogComp == "storage" ? (
          <GetStoragePopup onClose={() => setDialogComp()} />
        ) : (
          <CancelSubscribePopup onClose={() => setDialogComp()} />
        )}
      </Dialog>
    </Paper>
  );
}
