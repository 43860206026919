import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Loading = () => {
    return(
        <Box textAlign="center" p={3}>
            <CircularProgress />
            <Typography mt={2} color="text.secondary">
              Validating...
            </Typography>
          </Box>
    )
}

export default Loading;