import React, { createContext, useContext, useState, useEffect } from "react";
import { customAxios } from "./useAxios.js";
import { Outlet } from "react-router-dom";
import Loader from "components/common/loadingBar";
import MySwal from "utils/Swal.js";

const CredulockerAuthContext = createContext();

export const CredulockerAuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await customAxios.get("credulocker/user", {
        attachCredulockerToken: true,
        disableErrorHandling: true,
      });
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      logout();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logout = async () => {
    MySwal.showLoader("Logging Out...");
    await customAxios.get("logoutCredulockerUser");
    MySwal.close();
    setUser(null);
    localStorage.removeItem("cjwt");
  };

  return isLoading ? (
    <Loader />
  ) : (
    <CredulockerAuthContext.Provider
      value={{ user, logout, reloadUser: fetchData }}
    >
      {props.children ?? <Outlet />}
    </CredulockerAuthContext.Provider>
  );
};

export const useCredulockerAuth = () => {
  return useContext(CredulockerAuthContext);
};
