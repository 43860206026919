import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(
  Swal.mixin({
    confirmButtonColor: "#0d98ba",
    cancelButtonColor: "#a6aaab",
  }),
);

MySwal.fire = (options = {}) => {
  const finalOptions = {
    confirmButtonColor: "#0d98ba",
    cancelButtonColor: "#a6aaab",
    ...options,
    didOpen: () => {
      const swalContainer = document.querySelector(".swal2-container");
      if (swalContainer) {
        swalContainer.style.zIndex = 99999;
      }
      if (options.didOpen) {
        options.didOpen();
      }
    },
  };

  return Swal.fire(finalOptions);
};

MySwal.showLoader = (title, text = "") => {
  MySwal.fire({
    title,
    text,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      MySwal.showLoading();
    },
    willClose: () => {
      MySwal.hideLoading();
    },
  });
};

export default MySwal;
