import { Button, Stack, TextField } from "@mui/material";
import React from "react";

const ReferenceNumberForm = ({ handleRefNumberSubmit = () => { }, setEnteredRefNumb = () => { }, enteredRefNumb = "" }) => {
    return (
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
            <TextField
                variant="outlined"
                placeholder="Enter reference number"
                InputProps={{
                    sx: {
                        backgroundColor: "white.main",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        width: 240
                    }
                }}
                onChange={(e) => setEnteredRefNumb(e.target.value)}
            />
            <Button
                variant="contained"
                onClick={handleRefNumberSubmit}
                disabled={!enteredRefNumb.trim()}
                disableElevation
                sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                Verify
            </Button>
        </Stack>
    )
}

export default ReferenceNumberForm;