import React from "react";
import { Link, Paper, Stack, Typography } from "@mui/material";
import Dropzone from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const MyDropZone = ({ handleDrop = () => { } }) => {
    return (
        <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
                <Paper
                    {...getRootProps()}
                    variant="outlined"
                    component={Stack}
                    p={6}
                    spacing={2}
                    alignItems="center"
                    sx={{ cursor: "pointer", borderStyle: "dashed", borderWidth: "2px" }}
                >
                    <input {...getInputProps()} style={{ display: "none" }} />
                    <FileUploadOutlinedIcon color="primary" fontSize="large" />
                    <Typography color="text.main" variant='body2' align="center">
                        Drag and drop here or <Link sx={{ textDecoration: "none" }}>choose a file</Link> to
                        <br />
                        verify the document&apos;s authenticity!
                    </Typography>
                </Paper>
            )}
        </Dropzone>
    )
}

export default MyDropZone;