import React from "react";
import { Button, MenuItem, TextField, Stack } from "@mui/material";
import MySwal from "utils/Swal";
import validator from "validator";
import { customAxios } from "hooks/useAxios";

export const AddUserForm = (props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    displayName: null,
    email: null,
    // phone: null,
    role: null,
    // department: null,
    suspended: "Enabled",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const isInvalid =
      Object.values(formData).some(
        (value) => value === null || value === undefined || value === "",
      ) || !validator.isEmail(formData?.email || "");
    if (isInvalid) {
      MySwal.fire({
        title: "Please fill required fields",
        text: "One or more fields are empty or invalid",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      MySwal.showLoader("Processing...");
      const response = await customAxios.post("user", formData);
      if (response?.status === 200 || response?.status === 201) {
        MySwal.fire({
          title: "User Created!",
          text: "The user has been created!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        props.callback();
        props.handleClose();
      }
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mt={0.5}>
        <TextField
          required
          label="Name"
          variant="outlined"
          fullWidth
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              displayName: e.target.value,
            }))
          }
        />
        <TextField
          required
          type="email"
          label="Email"
          variant="outlined"
          fullWidth
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              email: e.target.value,
            }))
          }
        />
        {/* <TextField
          required
          label="Contact"
          variant="outlined"
          fullWidth
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              phone: e.target.value,
            }))
          }
        /> */}
        <TextField
          required
          label="Role"
          variant="outlined"
          fullWidth
          select
          defaultValue={formData?.role}
          onChange={(e) =>
            setFormData((prevState) => ({ ...prevState, role: e.target.value }))
          }
        >
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
          {/* <MenuItem value="DepartmentAdmin">Department Admin</MenuItem> */}
        </TextField>
        {/* <TextField
          label="Department"
          variant="outlined"
          fullWidth
          select
          defaultValue={formData?.department}
          onChange={(e) =>
            setFormData((prevState) => ({ ...prevState, department: e.target.value }))
          }
        >
          <MenuItem value="User">HR</MenuItem>
          <MenuItem value="Admin">Academic</MenuItem>
        </TextField> */}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Adding..." : "Add User"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};