import React from "react";
import axios from "axios";
import MySwal from "utils/Swal";
import Helpers from "utils/commonHelpers";

const { baseUrl } = Helpers.getEnvironmentDetails();
const getAuthToken = () => localStorage.getItem("jwt");
const getCredulockerToken = () => localStorage.getItem("cjwt");

const customAxios = axios.create({ withCredentials: true });

customAxios.interceptors.request.use((config) => {
  if (!config.url.startsWith("http://") && !config.url.startsWith("https://")) {
    config.url = baseUrl + config.url;
    if (config.attachCredulockerToken && getCredulockerToken()) {
      config.headers.authorization = "credulocker " + getCredulockerToken();
    } else if (!config.headers?.authorization && getAuthToken()) {
      config.headers.authorization = "itscredible " + getAuthToken();
    }
  }

  return config;
});

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.config?.disableErrorHandling) {
      return error?.response;
    }

    if (error?.response?.status === 401) {
      MySwal.fire({
        title: "Session Timed Out!",
        text: "Your session has expired. Please log in again.",
        icon: "error",
        confirmButtonText: "Login again!",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          window.location.href = `${process.env.REACT_APP_BASE_ROUTE}/auth/login`;
        }
      });
    } else {
      MySwal.fire({
        title: "Error!",
        text:
          error?.response?.data?.error?.message ||
          error?.response?.data?.error?.errorMessage ||
          error?.response?.data?.err?.msg ||
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong!",
        icon: "error",
      });
    }
    return error?.response;
  },
);

const useAxios = (url, config = {}) => {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await customAxios.get(url, config);
    setStatus(response?.status);
    if (response?.data) {
      setData(response.data);
    } else {
      setError(true);
    }
    setIsLoading(false);
  };

  const refresh = async () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, [url]);

  return [data, isLoading, error, refresh, status];
};

export { useAxios, customAxios };
