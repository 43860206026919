import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { Box, Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const ResultContainer = ({ children, onClose, validated }) => {
    return (
        <Box position="relative">
            <Paper component={Stack} textAlign="center" py={8} px={4} spacing={2} variant="outlined" sx={{ borderColor: validated ? "success.main" : "error.main" }} alignItems="center" >
                {children}
            </Paper>
            <IconButton
                onClick={onClose}
                sx={{ position: 'absolute', top: 4, right: 4, mt: 0 }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}

const ResultComponent = ({ response, closeResult, myFileName, showBranding }) => {
    const {
        validated,
        fileName,
        issuerName,
        uploadDate,
        isIssuerOnboardedAndVerified,
        docUrl,
        isExpired,
        expiryDate,
    } = response;


    if (validated) {
        if (isExpired) {
            return (
                <ResultContainer onClose={closeResult} validated={validated}>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <GppBadIcon color="error" />
                        <Typography fontWeight="700" variant="h4" color="error.main">
                            Document Expired
                        </Typography>
                    </Stack>
                    <Typography variant='body2'>
                        <b>&quot;{fileName}&quot;</b> was issued by {issuerName} on {moment(uploadDate).local().format("DD/MM/YYYY, hh:mm a")} but has been expired on {moment(expiryDate).local().format("DD/MM/YYYY, hh:mm a")}
                    </Typography>
                    <Button target="_blank" href={docUrl} sx={{ py: 0.3 }} variant="contained">
                        View Document
                    </Button>
                </ResultContainer>
            )
        }

        // Not Expired Valid Document
        return (
            <ResultContainer onClose={closeResult} validated={validated}>
                <Stack spacing={1} direction="row" alignItems="center">
                    <VerifiedUserIcon color="success" />
                    <Typography fontWeight="700" variant="h4" color="success.main">
                        {myFileName ? "Valid Document" : "Document Found"}
                    </Typography>
                </Stack>
                <Typography variant='body2'>
                    <b>&quot;{fileName}&quot;</b> was issued by {issuerName} on {moment(uploadDate).local().format("DD/MM/YYYY, hh:mm a")}.
                </Typography>
                <Button target="_blank" href={docUrl} sx={{ py: 0.3 }} variant="contained">
                    View Document
                </Button>
                {!isIssuerOnboardedAndVerified && <Paper component={Stack} px={1} py={0.5} sx={{ borderColor: "#c9243433", bgcolor: "#c924341a" }} spacing={1} direction="row" alignItems="center" variant="outlined">
                    <ReportProblemIcon sx={{ fontSize: 20 }} color="error" />
                    <Typography color="error.main" variant="body2">
                        <b>Warning!</b> {issuerName} is Unverified issuer{showBranding ? " on ItsCredible" : ""}.
                    </Typography>
                </Paper>}
            </ResultContainer>
        )
    }
    // Document Not Found
    return (
        <ResultContainer onClose={closeResult} validated={validated}>
            <Stack spacing={1} direction="row" alignItems="center">
                <GppBadIcon color="error" />
                <Typography fontWeight="700" variant="h4" color="error.main">
                    Document Not Found
                </Typography>
            </Stack>
            <Typography variant="body2">
                Oops! <b>{myFileName || "Entered Reference Number"}</b> is not issued on {showBranding ? "ItsCredible" : (response?.companyName || "Itscredible")}.
            </Typography>
        </ResultContainer>
    );
};

export default ResultComponent;